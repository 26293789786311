<template>
  <div class="d-flex flex-row">
    <v-menu
      v-model="isOpen"
      v-if="itemsCount > 0"
      bottom
      offset-y
      transition="scale-transition"
      max-height="70%"
      content-class="background-plain"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          class="ml-4"
          fab
          x-small
          color="primary"
          @click="on.click"
        >
          <icon-tooltip
            bottom
            :disabled="isOpen"
            :icon-name="'i.Recents'"
            :tooltip-text="$t('t.Recents')"
          />
        </v-btn>
      </template>
      <v-card v-if="isOpen">
        <v-list>
          <div v-if="menuItems.length <= 0">
            <v-skeleton-loader
              v-for="s in 5"
              :key="s"
              type="list-item"
              class="skeleton-items background-plain"
            />
          </div>
          <div v-else>
            <v-list-item
              v-for="(_, idx) in menuItems"
              :key="idx"
              link
              @click="navigateToDocument(_)"
            >
              <v-list-item-content>
                <account-ref
                  v-if="_.type === 'accounts'"
                  :id="_.id"
                  mixed
                />

                <span
                  v-if="_.type === 'account-contacts' || _.type === 'users'"
                  class=""
                >
                  {{_.fullname}}
                </span>
                <span
                  v-if="_.type === 'users'"
                  class=""
                >
                  {{_.name}}
                </span>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div class="d-flex flex-row justify-end">
            <v-btn
              icon
              class="mr-2 mt-2"
              @click="clearRecents()"
            >
              <v-icon>{{$icon('i.ClearList')}}</v-icon>
            </v-btn>
          </div>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import Search from '@/pages/search/controllers'
import recentsController from '@/recentsController'
import lodash from 'lodash'

export default {
  components: {
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    menuItems () {
      return this.itemIsCurrent(this.items[0]?.id) ? this.items.slice(1) : this.items
    },
    itemsCount () {
      return this.recents.length - (this.$route.path === `/${this.recents[0]?.documentType}/${this.recents[0]?.documentId}` ? 1 : 0)
    },
    recents () {
      return recentsController.recents
    }
  },
  watch: {
    isOpen () {
      this.prepareItems()
    }
  },
  data () {
    return {
      items: [],
      isOpen: false,
      innerItems: [],
      search: new Search(Search.actions.SUGGEST),
      showFirstItem: true
    }
  },
  mounted () {
    // Close popup with 'esc' key
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.isOpen = false
      }
    })
  },
  methods: {
    itemIsCurrent (id) {
      return this.$router.history.current.path.includes(id)
    },
    toggle () {
      this.isOpen = true
    },
    clearRecents () {
      recentsController.clearRecents(this.firstRecentIsCurrent)
      this.isOpen = false
    },
    async prepareItems () {
      if (!this.isOpen) {
        this.items = []
        return
      }

      const ids = lodash.map(this.recents, 'documentId')
      await this.search
        .chain(s => s.searchedIds.clear().include(ids))
        .execute()

      const items = lodash.map(this.recents, r => {
        return this.search.items.find(d => d.id === r.documentId)
      })

      this.items = items.filter(i => i)
    },
    navigateToDocument (d) {
      this.isOpen = false

      if (d.type === 'users') {
        d.type = 'account-contacts'
      }

      recentsController.goToRecent({
        documentType: d.type,
        documentId: d.id
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-btn
  text-transform capitalize
  word-wrap break-word

.tabs
  font-size 0.65em

.v-icon
  font-size 1em

.text-caption
  margin-top -10px

.skeleton-items
  width 300px
</style>
